<header class="d-flex flex-wrap justify-content-center header-bg fixed-top" style="z-index: 101">
	<a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none full-width">
		<!-- <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none my-auto ms-3"> -->
		<img class="navbar-brand" src="../../../assets/images/logo.svg" />
	</a>

	<!-- <div class="row mt-3">
		<div class="col-sm-3 mt-1">Languages:</div>
		<div class="col-sm-9">
			<dx-select-box [items]="languageList" (onValueChanged)="onValueChanged($event)" [stylingMode]="stylingMode" [value]="languageList[0]" displayExpr="displayName"> </dx-select-box>
		</div>
	</div> -->
</header>
